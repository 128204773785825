// FieldConfig.js

export class FieldConfig {
  constructor(key, label, placeholder = "", priority = 1, type = 'input', visibility = '', IsVisible = false,
    //  PagePermission = 'disable'
    //  ,
    place = '', value = "") {
    this.key = key;
    this.label = label;
    this.placeholder = placeholder;
    this.priority = priority;
    this.type = type;
    this.visibility = visibility;
    this.IsVisible = IsVisible;
    // this.PagePermission = PagePermission;
    this.place = place;
    this.value = value;

  }

  // Method to update visibility based on API response
  setVisibility(isVisible) {
    this.isVisible = isVisible;
  }
}




export const AddStockFields = [

  new FieldConfig("VendorId", "Supplier", "", 1, "inputselect", "ReadWrite", true, "top"),
  new FieldConfig("SKU", "SKU", "", 1, "inputselect", "ReadWrite", true, "top"),
  new FieldConfig("LotNumber", "Lot Number", "", 1, "inputselect", "ReadWrite", true, "top"),
  new FieldConfig("StockType", "Stock Type", "", 1, "dropdown", "ReadWrite", true, "top"),
  new FieldConfig("Branch", "Branch", "", 1, "dropdown", "ReadWrite", true, "top"),
  // new FieldConfig("unlabelgold", "Unlabel Gold", "", 1, "text", "ReadWrite", true, "top"),
  // new FieldConfig("unlabelsilver", "Unabel Silver", "", 1, "text", "ReadWrite", true, "top"),
  // new FieldConfig("unlabeldiamond", "Unlabel Diamond", "", 1, "text", "ReadWrite", true, "top"),
  // new FieldConfig("unlabelother", "Unlabel Other", "", 1, "text", "ReadWrite", true, "top"),
  //label
  new FieldConfig("CategoryId", "Category", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("ProductId", "Product", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("PurityId", "Purity", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("DesignId", "Design", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("CollectionId", "Collection", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("PacketId", "Packet", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("BoxId", "Box", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("mainstone", "Select stones", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("WeightCategory", "Weight Categories", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("Quantity", "Quantity", "", 1, "inputnumber", "ReadWrite", true, "middle"),
  new FieldConfig("Pieces", "Pieces", "", 1, "inputnumber", "ReadWrite", true, "middle"),
  new FieldConfig("images", "Images", "", 1, "popup", "ReadWrite", true, "middle"),
  new FieldConfig("TotalWeight", "Total Weight", "", 1, "inputdecimal", "ReadOnly", true, "middle"),
  new FieldConfig("PackingWeight", "Packing Weight", "", 1, "inputdecimal", "ReadWrite", true, "middle"),

  new FieldConfig("GrossWt", "grosswt", "", 1, "inputdecimal", "ReadWrite", true, "middle"),
  new FieldConfig("ClipWeight", "clipwt", "", 1, "inputdecimal", "ReadWrite", true, "middle"),
  new FieldConfig("TotalStoneWeight", "stonewt", "", 1, "inputdecimal", "ReadWrite", true, "middle"),
  new FieldConfig("NetWt", "netwt", "", 1, "inputdecimal", "ReadOnly", true, "middle"),
  new FieldConfig("MRP", "mrp", "", 1, "inputdecimal", "ReadWrite", true, "middle"),
  new FieldConfig("ProductName", "product name", "", 1, "inputtext", "ReadWrite", true, "middle"),
  new FieldConfig("Description", "description", "", 1, "inputtext", "ReadWrite", true, "middle"),
  new FieldConfig("Gender", "gender", "", 1, "dropdown", "ReadWrite", true, "middle"),
  new FieldConfig("MakingPercentage", "Making/%", "", 1, "inputnumber", "ReadWrite", true, "middle"),
  new FieldConfig("MakingPerGram", "Making/Grm", "", 1, "inputdecimal", "ReadWrite", true, "middle"),
  new FieldConfig("MakingFixedAmt", "Fixed Making", "", 1, "inputdecimal", "ReadWrite", true, "middle"),

  //loose diamonds
  new FieldConfig("DiamondShape", "Diamond shape1", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondClarity", "Diamond Clarity", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondColour", "Diamond Color", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondSize", "Diamond Size", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("dsleves", "Diamond sleves", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondWeight", "Diamond weight", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondSellRate", "D SellRate", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  // new FieldConfig("images", "Totalweight", "", 1, "imagesbox", "ReadWrite", false, "middle"),
  // new FieldConfig("totalamount", "Totalamount", "", 1, "inputnumber", "ReadWrite", true, "middle"),
  new FieldConfig("DiamondCut", "Diamond Cut", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondSettingType", "Diamond Setting", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  new FieldConfig("DiamondCertificate", "certificate", "", 1, "dinputselect", "ReadWrite", false, "middle"),
  //unlabel gold
  new FieldConfig("FinePercent", "fine", "", 1, "inputtext", "ReadWrite", false, "middle"),
  new FieldConfig("WastagePercent", "wastage", "", 1, "dropdown", "ReadWrite", false, "middle"),
  new FieldConfig("FineWastage", "fine+wastage", "", 1, "inputnumber", "ReadWrite", false, "middle"),

  new FieldConfig("addbtn", "Add", "", 1, "button", "ReadWrite", true, "middle"),
  new FieldConfig("importbtn", "Import", "", 1, "button", "ReadWrite", true, "middle"),
  //grid


  new FieldConfig("VendorId", "Vendor", "", 1, "dropdown", "ReadWrite", true, "grid"),
  new FieldConfig("SKU", "Sku", "", 1, "inputselect", "ReadWrite", true, "grid"),

  new FieldConfig("ItemCode", "ItemCode", "", 1, "inputtext", "ReadWrite", true, "grid"),
  new FieldConfig("RFIDCode", "RFIDCode", "", 1, "inputtext", "ReadWrite", true, "grid"),
  new FieldConfig("TIDNumber", "TidValue", "", 1, "inputtext", "ReadWrite", true, "grid"),
  new FieldConfig("TotalWeight", "TotalWT", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  // new FieldConfig("TotalStoneWeight", "TStoneWT", "", 1, "read", "ReadWrite", true, "grid"),

  new FieldConfig("GrossWt", "Grosswt", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("TotalStoneWeight", "StoneWt", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("ClipWeight", "ClipWt", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("ClipQuantity", "ClipQty", "", 1, "number", "ReadWrite", true, "grid"),
  new FieldConfig("NetWt", "Netwt", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("Size", "Size", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("Pieces", "Pieces", "", 1, "inputnumber", "ReadWrite", true, "grid"),
  new FieldConfig("Description", "Description", "", 1, "number", "ReadWrite", true, "grid"),
  new FieldConfig("ProductName", "Producttitle", "", 1, "inputtext", "ReadWrite", true, "grid"),
  new FieldConfig("HUIDCode", "Huid", "", 1, "inputtext", "ReadWrite", true, "grid"),
  new FieldConfig("HallmarkAmount", "Hallmark", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("TotalStoneAmount", "TotalSAmt", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("MakingPerGram", "Making/grm", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("MakingPercentage", "Making%", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("MakingFixedAmt", "MakingFixedAmt", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  // new FieldConfig("MakingFixedWastage", "MakingFixedWastage", "", 1, "number", "ReadWrite", true, "grid"),
  new FieldConfig("MRP", "MRP", "", 1, "inputdecimal", "ReadWrite", true, "grid"),
  new FieldConfig("OccassionName", "Occcassion", "", 1, "number", "ReadWrite", true, "grid"),
  new FieldConfig("Gender", "Gender", "", 1, "dropdown", "ReadWrite", true, "grid"),
  new FieldConfig("Status", "status", "", 1, "number", "ReadWrite", true, "grid"),
  new FieldConfig("Featured", "Featured", "", 1, "number", "ReadWrite", true, "grid"),
  // new FieldConfig("Metal", "Metal", "", 1, "number", "ReadWrite", true, "grid"),


  new FieldConfig("DiamondShape", "D Shape", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondClarity", "D Clarity", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondColour", "D Colour", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondCut", "D Cut", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondSize", "Diamond Size", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("Sieve", "Diamond Sleves", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondWeight", "Diamond wt", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondSellRate", "D Sell Rate", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondSettingType", "D SettingType", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondCertificate", "Diamond certificate", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondDescription", "D Description", "", 1, "text", "ReadWrite", false, "grid"),



  new FieldConfig("DiamondName", "Diamond Name", "", 1, "text", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondAmount", "Diamond Amount", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondPieces", "Diamond Pcs", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondRate", "Diamond Rate", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondPurchaseRate", "D Purchase Rate", "", 1, "number", "ReadWrite", false, "grid"),

  new FieldConfig("DiamondPurchaseAmount", "D Purchase Amt", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("DiamondSellAmount", "D Sell Amt", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("TotalDiamondWeight", "D Purchase Amt", "", 1, "number", "ReadWrite", false, "grid"),
  new FieldConfig("TotalDiamondAmount", "D Sell Amt", "", 1, "number", "ReadWrite", false, "grid"),

  new FieldConfig("Stones", "Stones", "", 1, "popup", "ReadWrite", true, "grid"),
  new FieldConfig("DButton", "Diamonds", "", 1, "popup", "ReadWrite", true, "grid"),
  new FieldConfig("IButton", "Images", "", 1, "popup", "ReadWrite", true, "grid"),


];



